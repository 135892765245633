.launch {
  &__countDown {
    display: flex;

    &__position {
      display: flex;
      flex-direction: column;
      font-size: 45px;
      font-weight: 500;
      margin: 10px;
      border: 2px solid;
      border-radius: 100px;
      width: 100px;
      height: 100px;
      align-items: center;
      justify-content: center;

      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.buy-now {
  text-decoration: none;
  color: rgb(247, 144, 43);
  background-color: white;
  padding: 15px 20px;
  border-radius: 30px;
}