.section {
  display: flex;
  width: 100%;
  overflow: hidden;
  justify-content: center;

  @media screen and (min-width: 720px) {
    display: flex;
    width: calc(100% - 60px);
    min-height: 50vh;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 60px 30px;
  }

  &__column {
    &__a {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      max-height: 100%;
    }

    &__b {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      max-height: 100%;
    }

    &__a,
    &__b {
      &:only-child {
        flex-basis: 100%;
      }
    }
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  @media screen and (max-width: 720px) {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    overflow: hidden;

    &__column {
      &__a {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        max-height: 100%;
      }

      &__b {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        max-height: 100%;
      }
    }
  }
}
